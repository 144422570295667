a{
	text-decoration: none !important;
	display: flex;
}

a span{
	padding:10px 10px
}

.footerRs{
	display: flex;
}

.footerRs > a{
	background-color: #54595F;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    vertical-align: center;
    align-content: space-around;
    flex-wrap: wrap;
    flex-direction: column-reverse;
	transition: all 0.3s ease-in-out;
}

.footerRs > a:hover{
	background-color: #fff;
}

.footerRs a span i{
	color:white;
}

.footerRs > a:hover span i{
	color: #54595F;
}

footer{
	background-color: #EAEAEA;
	padding: 20px 20px;
}

.color-icon-grey{
	color: #54595F;
}

.color-icon-white{
	color: #fff;
}

.color-icon-white:hover{
	color: #54595F;
}

.copyright{
	text-align: center;
	padding: 20px 20px;
	background-color: #DEDEDE;
	font-size:13px;
}