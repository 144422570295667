@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@200;300;400;500;600;700&display=swap');

*{
	font-family: 'Poppins', sans-serif;
}
.bg-gr{
	background-color: var(--gr-color);
}

.text-gr{
	color:var(--gr-color);
}

.btn-gr{
	background-color: var(--gr-color);
	color: #fff;
}

.btn-gr:hover{
	background-color: var(--gr-secondary);
	color: #fff;
}

.btn-gr-secondary{
	background-color: var(--gr-secondary);
	color: #fff;
}

.mr-2{
	margin-right:1rem;
}
