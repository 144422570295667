.galery-filter-porfolio-container {
    /* position: absolute; */
	
    display: inline-flex;
    overflow: hidden;
    filter: "alpha(opacity=10)";
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    max-width: 1000px;
}

.containerPortfolio{
	width: 300px;
	height:auto;
	position: relative;
	margin:15px 15px;
}

.containerPortfolio > img{
    height: 650px;
	width:100%;
}

.containerPortfolio > div.caption{
	position: absolute;
	bottom: -100px;
	transition: all 0.25s !important;
	left: 0;
	opacity: 0;
	width: 100%;
}

.containerPortfolio:hover > div.caption{
	bottom:0;
	background-color: #002b73d4;
	color: #fff;
	opacity:1;
	padding:15px;
}