.icon-service{
	width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #d6e3f9;
	background: #d6e3f9;
	text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
	font-size:1.5rem;
	color: #0C5ADB;
}

.card-home{
	min-height: 422px;
}