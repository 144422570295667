.homePortada{
	background-image: url(./../Image/slider2.webp);
	height:85vh;
	background-position:center;
	background-size: cover;
	position: relative;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 15%;
}

#sloganText h1{
	font-size: 5rem;
}

.text-rotate{
	transform: rotate(270deg);
    color: white;
	display: flex;
}

.text-rotate div{
	height: 2px;
    width: 44px;
    background-color: var(--gr-color);
    margin-top: 11px;
    margin-left: 20px;
}

.rrss{
	width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
	margin-top: 15px;
}

.rrss:hover{
	border: 1px solid  var(--gr-color);
	background-color: var(--gr-color);
	cursor: pointer;
}

.text-vertical{
	width: 126px;
    height: 225px;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

button.btn-gr{
	border-radius: 0px;
	padding:15px 30px;
}

/**/

.slide:hover,
.slide:focus {
  box-shadow: inset 9em 0 0 0 var(--hover);
}

.slide-contact:hover,
.slide-contact:focus{
	box-shadow: inset 16rem 0 0 0 var(--hover);
}

.slide-contact {
	--color: var(--gr-color);
	background:var(--gr-color);
	--hover: #fff;
	border-color: var(--gr-color);
  }


.slide {
  --color: var(--gr-color);
  background:var(--gr-color);
  --hover: #fff;
  border-color: var(--gr-color);
}

button {
  color: var(--hover);
  transition: 0.25s;
  
}
button:hover, button:focus {
  border-color: var(--hover);
  color: var(--gr-color);
}

button {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  padding: 1em 2em;
}

.sectionService{
	padding-top:100px;
	padding-bottom:100px;
}

.vector-service{
	position: absolute;
    top: -24px;
    left: -17px;
	filter: brightness( 98% ) contrast( 100% ) saturate( 141% ) blur( 0px ) hue-rotate( 256deg );
}

.vector-service-abouts{
	position: absolute;
    top: -24px;
    left: -17px;
	filter: sepia(100%) hue-rotate(190deg) saturate(500%);
}

.title-service{
	position: absolute;
    z-index: initial;
	width: 100%;
}

.card-home{
	padding: 40px 22px 40px 22px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 5px 0px 21px 0px rgba(0.009086608886723014, 0.009086608886723014, 0.009086608886723014, 0.15);
}

#abouts{
	background-image: linear-gradient(180deg, #002b73 86%, #0c5adb 100%);
	padding-top: 100px;
}

#text-encab{
	min-height: 100px;
}

#img-about{
	max-width: 500px;
}

ul{
	padding-left:0px !important;
}

.sectionPlans{
	background-image: url(https://www.agenciagruporincon.com/wp-content/uploads/2022/03/team-bg2.jpg);
    background-position: center center;
    background-size: cover;
	padding:100px;
}

.divider-plans > svg{
	height:55px;
	width:100%;
	fill: #fff;
}

#includePlans{
	margin-top:-50px;
	transform: rotate(180deg);
}

.elementor-shape{
	height:83px
}

.elementor-shape svg {
	display: block;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 84px;
	fill: white;
}

.typeWebSite{
	background-image: url(./../Image/backgroundDesc.webp);
	background-color: var(--gr-color);
	background-attachment: fixed;
	position: relative;
	padding-bottom: 50px;
}

.overlayContainer{
	height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
	background-color: var( --gr-secondary );
    opacity: 0.77;
}

 .contentDesc{
	display: block;
    position: relative;
}

.list-service{
	display:flex;
	justify-content: space-between;
}

.list-service > li > i.fa-circle-check{
	color: #fff;
}

.diviser-proyects{
	width: 17%;
    margin: 0 auto;
	align-items: center;
    display: flex;
	margin-bottom: 25px;
}

.text-portolio > h1{
	font-weight: 600;
}

*{
	--divider-border-style: solid;
    --divider-color: var( --gr-color );
    --divider-border-width: 2px;
}


.diviser-proyects::before{
	display: block;
    content: "";
    border-bottom: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color);
}

.diviser-proyects > span{
	color: var(--gr-color );
    font-family: "Poppins", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
	position: absolute;
    left: 46%;
    background: white;
    padding: 0 20px;
}

.portfolio{
	padding-top: 50px;
}

.tabProjects{
	border: none;
	padding:15px 20px;
}

.staticts{
	background-image: url(./../Image/bg07.jpg);
	padding: 80px 0px 400px 0px;
}

.content-statics p{
	color: #CED8E6;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.content-statics h1{
	color: #FBFBFB;
    font-size: 54px;
    font-weight: 600;
}

.content-statics i{
	font-size: 54px;
	margin-bottom:15px;
}

.content-form {
    background-color: #0c5adb;
    border-radius: 27px;
    box-shadow: 1px 5px 14px #eee;
}

.encab_form{
	padding:50px;
}

.form-contact{
	padding: 43px 53px;
	background:white;
	border-top-left-radius: 50px;
	padding-bottom: 150px;
}
  
.form-contact input {
    width: 100%;
    height: 50px;
    padding: 5px 10px;
    outline: none;
    margin: 10px 0px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

.form-contact textarea{
	width:100%;
	padding:5px 10px;
	outline:none;
	margin: 10px 0px;
	border:1px solid #e3e3e3;
	border-radius: 5px;
}

.form-contact input:focus,
.form-contact textarea:focus{
	border: 2px solid var(--gr-secondary);
}

.pqrs-collapse {
    background-color: white;
    border-radius: 27px;
    padding: 54px;
    box-shadow: 1px 5px 14px #eee;
}

.accordion-header {
    margin-bottom: 0;
    border-top: 1px solid #ccc;
}

.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    margin: 20px 0px;
}

section.contact.position-relative {
	top: -300px;
}

.vector-service-abouts-two{
	position: absolute;
    top: -24px;
    left: -17px;
    filter: sepia(0) hue-rotate(217deg) saturate(300%);
}

/*Mobile*/

@media (min-width: 360px) and (max-width: 724px) {

	.title-service{
		position: relative;
	}

	.sectionService{
		padding-left: 15px;
		padding-right: 15px;
	}

	.card-home{
		margin-bottom: 15px;
	}

	.homePortada{
		padding-left: 15px;
	}

	#sloganText h1 {
		font-size: 2rem;
	}

	#img-about {
		max-width: 359px;
	}

	.text-vertical{
		width:80px;
	}

	#sloganText h5{
		font-size:1rem;
	}

	.list-service {
		flex-wrap: wrap;
	}

	.content-form {
		position: relative;
	}

	.pqrs-collapse {
		position: relative;
		width: auto;
	}

	section.contact.position-relative {
		top: -100px;
	}
}

@media (min-width: 728px) and (max-width: 1024px) {
	section.contact.position-relative {
		top: -50px;
	}
}

@media (min-width: 1025px) and (max-width: 1920px) {
	section.contact.position-relative {
		top: -100px;
		height: 90vh;
	}
}