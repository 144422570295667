#background-checkout{
    background-color: #f5f5f5;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen-full{
    height: 100vh;
}

.text-muted{
    font-size: 0.7rem;
    line-height: 0;
}

.button-payment{
    background-color: #81c784;
}

.card-search-client{
    max-width: 25%;
}

@media (max-width: 767px) {
    .card-search-client{
        max-width: 100%;
    }
}

@media (min-width: 768px) and ( max-width: 1024px ) {
    .card-search-client{
        max-width: 50%;
    }
}