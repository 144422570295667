@import url('./../App.css');

.table-price{
	background-color: #fff;
	box-shadow: 5px 10px 33px #ccc;
	border-radius: 10px;
	padding-bottom: 10px;
}

.amount-price{
	display: flex;
    align-items: center;
    justify-content: center;
	color: var(--gr-secondary);
}

.desc-service{
	font-size:2rem;
	font-weight: 800;
}

.symbol-price{
	align-items: center;
    display: flex;
}

.cod-price{
	align-items: center;
    display: flex;
}

.header-table {
    padding: 10px 20px;
    color: #fff;
    text-transform: uppercase;
}

.header-table > h1{
	font-size:1.2rem
}


.recommended{
	background-color: var(--gr-color);
}

.classic{
	background-color: var(--gr-secondary);
}

.check{
	max-width:15px;
}

.body-price ul{
	padding:20px !important;
}

.body-price ul > li{
	border-bottom: 1px solid #ccc;
	padding:15px;
    font-size: 0.9rem;
}

button.quote{
	border-radius: 0px;
	padding:10px 20px;
	margin-bottom: 10px;
}

.elementor-price-table__ribbon {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 150px;
    overflow: hidden;
    height: 150px;
}

.elementor-price-table__ribbon-inner {
    text-align: center;
    left: 0;
    width: 200%;
    -webkit-transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
    -ms-transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
    transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
    margin-top: 35px;
    font-size: 0.5rem;
    line-height: 2;
    font-weight: 800;
    text-transform: uppercase;
    background: var(--gr-secondary);
	color: #fff;
}

.divider-plans{
	margin-bottom:150px;
}

.sectionPlans > .text-center.text-gr{
	text-transform: uppercase;
	font-weight: 800;
	margin-bottom: 30px;
}

@media (min-width: 360px) and (max-width:920px){
	.sectionPlans {
		padding: 39px;
	}

	.table-price {
		background-color: #fff;
		box-shadow: 5px 10px 33px #ccc;
		margin-top: 15px;
	}
}