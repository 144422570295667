@import url('./../App.css');

header{
	width: 100%;
}

header nav ul{
	display:flex;
	justify-content: flex-end;
}

ul{
	list-style: none;
}

.logo {
	margin-top:10px;
    max-width: 150px;
}

.linkMenu{
	font-size:1.3rem;
}

.linkMenu:hover{
	color: var(--gr-color) !important;
}

.bg-gr{
	background-color:#002B73D4;
}

.btn-mobile{
	display:none;
}

.linkMenu {
    font-size: 1rem;
    margin-right: 0.5rem;
}

a.highlight-button.linkMenu.nav-link.text-white {
    border-radius: 20px;
    border: 1px solid #000;
    background: black;
}

a:hover.highlight-button.linkMenu.nav-link.text-white {
    border-radius: 20px;
    border: 1px solid #fff;
    background: #fff;
}

.highlight-payment{
	border: 1px solid #fff;
	border-radius: 20px;
	color: #fff;
}

.highlight-payment:hover{
	border: 1px solid var(--gr-color);
	border-radius: 20px;
	color: var(--gr-color);
}

@media (min-width: 360px) and (max-width: 768px){
	.menu-desktop{
		display: none !important;
	}

	.logo {
		max-width: 200px;
	}

	.nav-mobile.nav {
		display: contents;
	}

	.btn-mobile{
		display:block;
	}

	.galery-filter-porfolio-container {
		/* position: absolute; */
		display: inline-flex;
		overflow: hidden;
		filter: "alpha(opacity=10)";
		margin: 0;
		padding: 0;
		flex-wrap: wrap;
		max-width: 1000px;
		justify-content: center;
	}

	.pqrs-collapse {
		top: 0;
	}

	.content-form {
		top: 0px;
	}

	.staticts {
		padding: 80px 0px 66px 0px;
	}
}